import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

window.cookies = {
	set: function (key, value) {
		let isHttps = window.location.protocol == 'https:';
		document.cookie = `${key}=${value};path=${BASE_URI}${isHttps ? ';secure' : ';samesite=strict'}`
	},
	rm: function (key) {
		document.cookie = `${key}=`
	},
	get: function (key) {
		let matches = document.cookie.match(new RegExp("(?:^|; )" + key.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
		return matches ? decodeURIComponent(matches[1]) : undefined;
	}
}

function init() {
	const environment = process.env.NODE_ENV || 'development';

	const config = {
		dsn: process.env.SENTRY_DSN,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 1.0,
		environment: environment
	};

	if (environment == 'production') {
		config.tracesSampleRate = 0.5;
	}
	
	Sentry.init(config);

	let uid = cookies.get('uid');
	if (uid) {
		Sentry.setUser({ id: uid });
	}
}

if (process.env.SENTRY_DSN) init();
